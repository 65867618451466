import { useMutation, useQueryClient } from "@tanstack/react-query"
import { apierp } from "../../services/api"


const submit = async (dados: any) => {
    const dadosx = {
        "metodo": "salvarPedidoVendaNoBling",
        "param": {
            dados
        }
    }
    const response = await apierp.post('pedidosvenda', dadosx)
    return response?.data
}

export function useMutatePedidoVendaToBling() {

    const queryClient = useQueryClient()
    const mutate = useMutation({
        mutationFn: submit,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['pedidosvenda']})
            queryClient.invalidateQueries({ queryKey: ['pedidovenda']})
        }
    })

    return mutate
}
