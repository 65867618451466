import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { dateFormat } from '../../../utils/dateFunctions'
import { message } from 'antd'

export default function useClientesRanking(param: any) {
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()
  const [clientesRanking, setClientesRanking] = useState<any[]>([])
  const [totalReg, setTotalReg] = useState<number>(0)
  // const [empresas, setEmpresas] = useState<any[]>([])

  const obterRankingClientes = useCallback(async function () {
    if (!param) return
    // console.log('obterRankingClientes', param)
    try {
      iniciarProcessamento()
      // const inicio = performance.now();
      const response1 = await apierp.post(`clientes/obterranking`, param)
      const data1 = await response1.data as any;
      // const duracao = Math.round((performance.now() - inicio) / 100) / 10;
      // console.log(`obterRankingClientes: ${duracao} segundos`)
      setTotalReg(data1.totalReg)
      setClientesRanking(data1.clientesRanking)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }

  }, [param])

  function voltar() {
    setClientesRanking([])
  }
  function reload() {
    obterRankingClientes()
  }

  useEffect(() => {
    setClientesRanking([])
    obterRankingClientes()
  }, [obterRankingClientes])

  return {
    clientesRanking,
    totalReg,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload
  }

}