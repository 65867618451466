import { useEffect, useState } from "react"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';

import { useAuth } from "../../../contexts/auth";
import { apierp } from '../../../services/api';
import { customMessage } from "../../../components/template/CustomMessage";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import Spinner from "../../../components/template/Spinner";
import useWindowSize from "../../../hooks/useWindowSize";
import { NoStyleItemContext } from "antd/lib/form/context";
import { copyToClipboard, downloadToCsv, printDiv } from "../../../utils/functions";
import Layout from "../../../components/template/Layout";
import { useGeral } from "../../../contexts/geral";
import { render } from "@testing-library/react";
const { Title, Text, Paragraph } = Typography;


//campos contasCorrentes
// id: string;
// bancoId: string;
// agencia?: string;
// contaCorrente?: string;
// tipoEnv?: string;
// oAuthBaseUrl?: string;
// pixBaseUrl?: string;
// endPoint?: string;
// apiDevAppKey?: string;
// apiClientId?: string;
// apiClientSecret?: string;
// apiBasicAuth?: string;
// apiChavePix?: string;


export default function ContaCorrente() {
    const { Logout, user } = useAuth();
    const usuario: any = user
    const { userMenu, menuSelected, userTenant, } = useGeral();
    const menu = userMenu.find((x: any) => x.id == menuSelected)

    const { Option } = Select;
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [toggleState, setToggleState] = useState(false)

    const [rowSelected, setRowSelected] = useState(-1)
    const [lastSelected, setLastSelected] = useState(-1)
    const [isInclusao, setIsInclusao] = useState(false)

    const [contaCorrentes, setContaCorrentes] = useState([] as any[])
    const [contaCorrente, setContaCorrente] = useState(null as any)
    const [buttonsDisabled, setButtonsDisabled] = useState(false)

    const [empresas, setEmpresas] = useState([] as any[])
    const [innerW] = useWindowSize();

    useEffect(() => {
        setLoading(true)
        setTimeout(async function () {
            try {
                await loadEmpresas()
            } catch (err) {
                if (customMessage(err) === 403) Logout()
            }
            setLoading(false)
        }, 300);
    }, [])

    useEffect(() => {
        setLoading(true)
        setTimeout(async function () {
            try {
                await loadContaCorrentes()
            } catch (err) {
                if (customMessage(err) === 403) Logout()
            }
            setLoading(false)
        }, 500);
    }, [toggleState])

    async function loadContaCorrentes() {
        const response = await apierp.get(`contasCorrentes`);
        const data = response.data as any[];
        setContaCorrentes(data);
    }
    async function loadEmpresas() {
        const response = await apierp.get(`empresas`);
        const data = response.data as any[];
        setEmpresas(data);
    }

    function handleRow(record: any, index: any) {
        setLastSelected(index)
        if (rowSelected === index) {
            setRowSelected(-1)
            // setStatusCrmBlocked(true)
        } else {
            setRowSelected(index)
            setIsInclusao(false)
            showDrawer()
            const contaCorrente: any = {
                id: record.id,
                empresaId: record.empresaId,
                bancoId: record.bancoId,
                agencia: record.agencia,
                contaCorrente: record.contaCorrente,
                tipoEnv: record.tipoEnv,
                oAuthBaseUrl: record.oAuthBaseUrl,
                pixBaseUrl: record.pixBaseUrl,
                endPoint: record.endPoint,
                apiDevAppKey: record.apiDevAppKey,
                apiClientId: record.apiClientId,
                apiClientSecret: record.apiClientSecret,
                apiBasicAuth: record.apiBasicAuth,
                apiChavePix: record.apiChavePix
            }
            fillForm(contaCorrente)
        }
    }


    const showDrawer = () => {
        setButtonsDisabled(false)
        setDrawerVisible(true)
    };

    const hideDrawer = () => {
        setButtonsDisabled(false)
        setRowSelected(-1)
        setContaCorrente(null)
        setDrawerVisible(false)
    };

    async function handleSubmit(dados: any) {
        setButtonsDisabled(true)
        if (dados.id === '') {
            delete dados.id
            //CREATE
            try {
                const result = await apierp.post('contasCorrentes', dados);
                setToggleState(!toggleState)
                message.success('Conta corrente incluida com sucesso!');

                hideDrawer()
            } catch (err) {
                if (customMessage(err) === 403) Logout()
            }
        } else {
            //UPDATE
            try {
                const result = await apierp.put(`contasCorrentes/${dados.id}`, dados);
                setToggleState(!toggleState)
                message.success('Conta corrente alterada com sucesso!');
                hideDrawer()
            } catch (err) {
                if (customMessage(err) === 403) Logout()
            }
        }
    };

    async function handleDelete() {
        const dados = form.getFieldsValue()
        try {
            await apierp.delete(`contasCorrentes/${dados.id}`);
            setToggleState(!toggleState)
            setLastSelected(-1)
            message.success('Conta corrente excluida com sucesso!');
            hideDrawer()
        } catch (err) {
            if (customMessage(err) === 403) Logout()
        }
    };

    function confirmDelete(e: any) {
        handleDelete();
    }

    function cancelDelete(e: any) {
        message.error('Conta corrente não foi excluída');
    }

    const novaContaCorrente = () => {
        setIsInclusao(true)
        // setStatusCrmBlocked(false)
        showDrawer()
        const contaCorrente: any = {
            id: '',
            empresaId: '',
            bancoId: '',
            agencia: '',
            contaCorrente: '',
            tipoEnv: '',
            oAuthBaseUrl: '',
            pixBaseUrl: '',
            endPoint: '',
            apiDevAppKey: '',
            apiClientId: '',
            apiClientSecret: '',
            apiBasicAuth: '',
            apiChavePix: ''
        }
        fillForm(contaCorrente)
    }

    const fillForm = (contaCorrente: any) => {
        form.setFieldsValue({
            id: contaCorrente.id,
            empresaId: contaCorrente.empresaId,
            bancoId: contaCorrente.bancoId,
            agencia: contaCorrente.agencia,
            contaCorrente: contaCorrente.contaCorrente,
            tipoEnv: contaCorrente.tipoEnv,
            oAuthBaseUrl: contaCorrente.oAuthBaseUrl,
            pixBaseUrl: contaCorrente.pixBaseUrl,
            endPoint: contaCorrente.endPoint,
            apiDevAppKey: contaCorrente.apiDevAppKey,
            apiClientId: contaCorrente.apiClientId,
            apiClientSecret: contaCorrente.apiClientSecret,
            apiBasicAuth: contaCorrente.apiBasicAuth,
            apiChavePix: contaCorrente.apiChavePix
        });
    };

    // const resetForm = () => {
    //     setRowSelected(-1)
    //     novaContaCorrente() // form.resetFields();
    // };

    const columns = [
        // {
        //   width: '10px',
        //   onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
        // },
        {
            title: 'Id',
            dataIndex: 'id',
            ellipsis: true,
            width: '40px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
        },
        {
            title: 'Empresa',
            dataIndex: 'empresaId',
            ellipsis: true,
            width: '80px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{empresas.find(x => x.id === text)?.nomeCurto || ''}</>) },
        },
        {
            title: 'Banco',
            dataIndex: 'bancoId',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
        },
        {
            title: 'Agencia',
            dataIndex: 'agencia',
            ellipsis: true,
            width: '70px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
        },
        {
            title: 'C/Corrente',
            dataIndex: 'contaCorrente',
            ellipsis: true,
            width: '100px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
        },
        {
            title: 'Tipo Env',
            dataIndex: 'tipoEnv',
            ellipsis: true,
            width: '80px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
        },
        {
            title: 'ABaseUrl',
            dataIndex: 'oAuthBaseUrl',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{text ? 'Sim' : 'Nao'}</>) },
        },
        {
            title: 'PixBaseUrl',
            dataIndex: 'pixBaseUrl',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{text ? 'Sim' : 'Nao'}</>) },
        },
        {
            title: 'DevAppKey',
            dataIndex: 'apiDevAppKey',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{text ? 'Sim' : 'Nao'}</>) },
        },
        {
            title: 'ClientId',
            dataIndex: 'apiClientId',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{text ? 'Sim' : 'Nao'}</>) },
        },
        {
            title: 'ClientSecret',
            dataIndex: 'apiClientSecret',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{text ? 'Sim' : 'Nao'}</>) },
        },
        {
            title: 'BasicAuth',
            dataIndex: 'apiBasicAuth',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{text ? 'Sim' : 'Nao'}</>) },
        },
        {
            title: 'PIX',
            dataIndex: 'apiChavePix',
            ellipsis: true,
            width: '60px',
            className: 'text-left',
            onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" }),
            render: (text: any, record: any, index: any) => { return (<>{text ? 'Sim' : 'Nao'}</>) },
        },
    ]

    const botoes = () => {
        return (
            <Space size={10}>
                <Button onClick={() => printDiv('printableDiv-contacorrente', 'contacorrente')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
                <Button onClick={() => copyToClipboard('contacorrente')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
                <Button onClick={() => downloadToCsv('contacorrente', "ContaCorrente.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
                <Button onClick={novaContaCorrente} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
            </Space>
        )
    }

    const wTab = 428 + 430 // liquido = x-17
    const htb = `calc(100vh - 149px - 110px)`
    const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb
    const wDrawer = innerW > 720 ? 720 : innerW

    return (
        <Layout titulo={'Contas Correntes'} botoes={botoes()}>
            <div className="bg-blue-30 mx-5 overflow-x-auto">
                <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
                    <div id="printableDiv-status" className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
                        <h1 className='hidden mb-1 text-2xl font-bold'>Status CRM</h1>
                        {/* TABLE */}
                        <div className="flex flex-col justify-between bg-[#f8f9fb]" style={{ maxHeight: htb }}> {/** ou heigth */}
                            <div className="envelopetable ">
                                <Table id='status' dataSource={contaCorrentes} columns={columns} size="small" rowKey="id"
                                    rowClassName={(record, index) => (index === rowSelected ? "rowSelected" : "")}
                                    pagination={false}
                                    scroll={{ y: hsc }}
                                    onRow={(record: any, rowIndex: any) => {
                                        return {
                                            onClick: event => { handleRow(record, rowIndex) }, // click row
                                            className: lastSelected === rowIndex ? "lastSelected" : ""
                                        };
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* DRAWER FORM */}
            <Drawer
                title={(isInclusao ? "Criar um novo" : "Alterar") + " Conta Corrente"}
                width={wDrawer}
                closable={false}
                maskClosable={false}
                onClose={hideDrawer}
                visible={drawerVisible}
                headerStyle={{ borderRadius: "0" }}
                bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
            >
                <div className="inner-drawer-body">
                    <Form layout="vertical" form={form} onFinish={handleSubmit} >
                        <Title level={5}>{form.getFieldValue('nome')}</Title>
                        <Form.Item name="id" hidden noStyle></Form.Item>
                        <Form.Item name="endPoint" hidden noStyle></Form.Item>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="empresaId"
                                    label="Empresa"
                                    rules={[{ required: true, message: 'Selecione uma empresa' }]}
                                >
                                    <Select placeholder="Selecione uma empresa">
                                        {empresas.map(empresa => (
                                            <Select.Option key={empresa.id} value={empresa.id}>
                                                {empresa.nome}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="bancoId"
                                    label="Banco"
                                    rules={[{ required: true, message: 'Entre com o código do banco' }]}
                                >
                                    <Input placeholder="Código do banco" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="agencia"
                                    label="Agência"
                                >
                                    <Input placeholder="Número da agência" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="contaCorrente"
                                    label="C/C"
                                >
                                    <Input placeholder="Número da conta" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="tipoEnv"
                                    label="Tipo"
                                >
                                    <Input placeholder="Tipo de envio" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="oAuthBaseUrl"
                                    label="OAuth Base URL"
                                >
                                    <Input placeholder="URL base para autenticação OAuth" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="pixBaseUrl"
                                    label="PIX Base URL"
                                >
                                    <Input placeholder="URL base para serviços PIX" />
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="endPoint"
                                    label="Endpoint"
                                >
                                    <Input.TextArea rows={2} placeholder="Endpoint para requisições" />
                                </Form.Item>
                            </Col>
                        </Row> */}

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="apiDevAppKey"
                                    label="API Developer App Key"
                                >
                                    <Input  placeholder="Chave do desenvolvedor para API" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="apiClientId"
                                    label="API Client ID"
                                >
                                    <Input.TextArea rows={2} placeholder="ID do cliente para API" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="apiClientSecret"
                                    label="API Client Secret"
                                >
                                    <Input.TextArea rows={3} placeholder="Chave secreta do cliente para API" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="apiBasicAuth"
                                    label="API Basic Auth"
                                >
                                    <Input.TextArea rows={6} placeholder="Autenticação Basic para API" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="apiChavePix"
                                    label="Chave PIX"
                                >
                                    <Input placeholder="Chave PIX para transações" />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </div>
                <Space className="drawer-footer">
                    <Space>
                        <Button onClick={hideDrawer}>Cancel</Button>
                        {/* <Button onClick={resetForm}>Reset</Button> */}
                        <Popconfirm
                            title="Deseja realmente excluir este status?"
                            onConfirm={confirmDelete}
                            onCancel={cancelDelete}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button onClick={() => { }} type="primary" danger disabled={isInclusao || buttonsDisabled} >Delete</Button>
                        </Popconfirm>

                        <Button onClick={form.submit} disabled={buttonsDisabled} type="primary">Submit</Button>
                    </Space>
                </Space>
            </Drawer>

        </Layout>
    )
}