
import * as React from "react"
import { useEffect, useState } from "react"
import { format, parseISO } from "date-fns";
import { OrderedListOutlined, ReloadOutlined } from '@ant-design/icons';  // Add this import at the top of the file

import Layout from "../../../components/template/Layout"

import { Button, InputNumber, message, Radio, RadioChangeEvent, Space, Typography } from "antd";
import useClientes from "./useClientes";
import { formatarCpfCnpj } from "../../../utils/functions";
import useWindowSize from "../../../hooks/useWindowSize";
import SpinnerModal from "../../../components/template/SpinnerModal";
import SpinnerNew from "../../../components/template/SpinnerNew";
import ModalClienteDetalhe from "../../__Shared/Clientes/ModalClienteDetalhe";
import { apierp } from "../../../services/api";
import { differenceInDaysBetweenDates } from "../../../utils/dateFunctions";
import { render } from "@testing-library/react";
const { Title, Text, Paragraph } = Typography;

export default function ClienteVinculo() {

  // tamanho: QUANTIDADE DE CARACTERES NO NOME A COMPARAR PARA CONSIDERAR VINCULAVEIS - MINIMO 10 e MAXIMO 20 
  const [tamanho, setTamanho] = useState(30)
  const { clientes, processando, reload } = useClientes(tamanho)
  const [cliente, setCliente] = useState(null as any)
  const [pedidosCliente, setPedidosCliente] = useState([] as any)

  const [showModalCliente, setShowModalCliente] = useState(false)
  const [clientesToVinc, setClientesToVinc] = useState([] as any)
  const [filtro, setFiltro] = useState('T' as any) // T = todos, V = vinculados, L = Vinculáveis (pela quant caracteres iguais no nome)

  // useEffect(() => {
  //   // console.log('useEffect unico', tamanho)
  //   setTamanho(20)
  // }, [])

  async function loadCliente(id: any) {
    // console.log('dados clientes', dados)
    setCliente(null)
    setPedidosCliente([])
    const response = await apierp.post(`clientes/obterclienteporid`, { clienteId: id })
    const data = await response.data as any;
    setCliente(data)
    const response2 = await apierp.post(`clientes/obterpedidoscliente`, { clienteId: id })
    const data2 = await response2.data as any;
    setPedidosCliente(data2.pedidosCliente)
    // console.log('data-----', data)
    // console.log('data2-----', data2)
    // return data
  }

  const hideModalCliente = () => {
    setCliente(null)
    setShowModalCliente(false)
  };

  function handleClickCliente(c: any) {
    loadCliente(c.id)
    setShowModalCliente(true)
  }

  function handleClickVincular(c: any) {
    // console.log('handleClickVincular', c)
    if (+c.vinculado === 1 && +c.isMaster === 0) {
      if (window.confirm('Deseja realmente desvincular este cliente?')) {
        desvincularCliente(c)
      } else {
        // console.log('cancelou')
      }
      return
    }

    if (clientesToVinc.length === 2) return
    if (clientesToVinc.length === 1 && clientesToVinc[0].id === c.id) return
    setClientesToVinc([...clientesToVinc, c])
  }

  const handleButtonCancel = () => {
    setClientesToVinc([])
  }

  const handleButtonVincular = () => {
    // console.log('handleButtonVincular', clientesToVinc)
    vincularClientes(clientesToVinc)
  }

  async function desvincularCliente(cliente: any) {
    // console.log('desvincularCliente', cliente)
    await apierp.post(`clientes/desvincular`, { clienteId: cliente.id })
    reload()
    setClientesToVinc([])
  }

  async function vincularClientes(clientes: any) {
    // console.log('vincularClientes', clientes)
    await apierp.post(`clientes/vincular`, { masterId: clientes[0].id, slaveId: clientes[1].id })
    reload()
    setClientesToVinc([])
  }

  function renderAcao(c: any) {
    if (!c) return ''
    // let acao = +c?.vinculado && !+c.isMaster ? 'Desvincular' : +c?.isMaster ? 'Vincular + ' : 'Vincular'
    if (+c?.vinculado === 1 && +c?.isMaster === 0 && clientesToVinc.length === 0) { return 'Desvincular' }
    if (+c?.isMaster === 1 && clientesToVinc.length === 0) { return 'Vincular +' }
    if (+c?.isMaster === 1 && clientesToVinc.length > 0) { return '' }
    if (clientesToVinc.length === 2) { return '' }
    if (+c?.vinculado === 0 && clientesToVinc.filter((x: any) => x.id === c.id).length === 0) { return 'Vincular' }
  }

  async function rankear() {
    const ret = await apierp.post(`clientes/rankear`)
    message.success(ret.data.message);

  }

  const onChangeFilter = (e: RadioChangeEvent) => {
    setFiltro(e.target.value);
  };

  const onChangeTamanho = (value: any) => {
    setTamanho(value)
  };

  // calcula resumo dos pedidos
  if (pedidosCliente.length > 0) pedidosCliente[pedidosCliente.length - 1].intervalo = 0
  let intervaloMinimo = pedidosCliente.length > 1 ? 999999 : 0
  let intervaloMaximo = 0
  let intervaloTotal = 0
  let totalVendaCliente = pedidosCliente.reduce((acc: any, cur: any) => acc + cur.totalvenda, 0)
  let totalProdutos = pedidosCliente.reduce((acc: any, cur: any) => acc + cur.totalprodutos, 0)
  let totalFretes = pedidosCliente.reduce((acc: any, cur: any) => acc + cur.valorfrete, 0)

  for (let i = pedidosCliente.length - 2; i >= 0; i--) {
    const intervalo = differenceInDaysBetweenDates(pedidosCliente[i].data, pedidosCliente[i + 1].data)
    pedidosCliente[i].intervalo = intervalo
    intervaloMinimo = intervalo < intervaloMinimo ? intervalo : intervaloMinimo
    intervaloMaximo = intervalo > intervaloMaximo ? intervalo : intervaloMaximo
    intervaloTotal += intervalo
  }
  const intervaloMedio = pedidosCliente.length > 1 ? (intervaloTotal / (pedidosCliente.length - 1)).toFixed(0) : 0

  // console.log('pedidosCliente', pedidosCliente)

  const ultimoPedido = pedidosCliente[0]
  const diasUltimoPedido = differenceInDaysBetweenDates(format(new Date(), 'yyyy-MM-dd'), ultimoPedido?.data)
  const resumo = {
    pedidosCliente,
    // totalVendaCliente,
    totalProdutos,
    totalFretes,
    ultimoPedido,
    diasUltimoPedido,
    intervaloMinimo,
    intervaloMedio,
    intervaloMaximo,
  }

  let [innerW, innerH] = useWindowSize()

  const wTab = 1308
  const hTab = innerH - 350 - 80 - 40
  // console.log(window.innerWidth, window.innerHeight)

  const botoes = () => {
    return (
      <div className="flex flex-1 justify-between items-center mx-4">
      <Button className="w-32" type="primary" icon={<ReloadOutlined />} onClick={() => reload()}>Atualizar</Button>
      <Space size={5}>
        <h3 className="pt-[6px] mr-3">Filtros:</h3>
        <Radio.Group className="font-semibold" onChange={onChangeFilter} value={filtro}>
          <Radio value={'T'}>Todos</Radio>
          <Radio value={'V'}>Vinculados</Radio>
          <Radio value={'L'}>Vinculáveis</Radio>
        </Radio.Group>
        <InputNumber controls={true} className={ `w-16` } min={10} max={30} defaultValue={30} onChange={onChangeTamanho} />
        {/* <InputNumber className={ filtro !== 'L' ? 'invisible' : ''} min={10} max={30} defaultValue={20} onChange={onChangeTamanho} /> */}
      </Space>
      <Button className="w-32" type="primary" icon={<OrderedListOutlined />} onClick={() => rankear()}>Rankear</Button>
      </div>
    )
  }

  return (
    <Layout titulo={"Clientes"} botoes={botoes()}>
      <Title className="title mx-auto" >{"Cadastro de Clientes"}</Title>
      <SpinnerNew loading={processando} />
      <div className="overflow-x-auto">
        <div className="flex flex-col mx-auto" style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          {/* CLIENTES */}
          <div hidden={clientes.length === 0} className="mt-2 rounded bg-slatex-200 ">
            {/* <h2 className="title mt-3 mb-1">Clientes</h2> */}
            <div className={` `}>
              <div key={'cab1'} className="cabec flex bg-[#0f172a] px-3 p-1 mb-2 text-white gap-2 font-bold">
                <div className="flex gap-2 min-w-max">
                  <div className="w-12 text-left">Item</div>
                  <div className="w-12 text-right">Id</div>
                  <div className="w-36 text-left">Cnpj/Cpf</div>
                  <div className="w-[470px] text-left">Nome</div>
                  <div className="w-44 text-left">Cidade</div>
                  <div className="w-7 text-left">UF</div>
                  <div className="w-56 text-left">Telefones</div>
                  {/*
                  <div className="w-4 text-center">M</div>
                  <div className="w-4 text-center">V</div>
                  <div className="w-4 text-center">L</div> */}
                </div>
                <div className="w-[80px] text-left">Ações</div>

              </div>
              <div className=" overflow-y-auto" style={{ maxHeight: `${hTab}px` }}>
                {(clientes || []).filter((x: any) => (filtro === 'V' && +x.vinculado) || (filtro === 'L' && +x.vinculavel) || (filtro === 'T')).map((c: any, i: number) => {
                  const font = +c?.isMaster ? 'font-bold' : ''
                  const corv = +c?.vinculado && !+c.isMaster ? 'font-bold text-red-400' : ''
                  const corl = +c?.vinculavel ? 'font-bold text-blue-500' : ''
                  const cort = +c?.vinculavel ? 'bg-yellow-300' : ''
                  // const corz = +c?.vinculavel === 1 ? 'font-semibold text-blue-500' : ''
                  let nome1 = c?.nome.substring(0, tamanho)
                  const nome2 = c?.nome.substring(tamanho, 50)
                  let acao = renderAcao(c)
                  return (
                    <div key={c.id} className={`flex px-3 p-1 gap-2 border-solid border-0 border-b border-[#b0d2ff] cursor-pointer hover:bg-slate-100 ${font} ${corv} ${corl}`}
                    >
                      <div className="flex gap-2 min-w-max" onClick={() => { handleClickCliente(c) }}>
                        <div className="w-12 text-left">{i + 1}</div>
                        <div className="w-12 text-right">{c?.id}</div>
                        <div className="w-36 text-left">{formatarCpfCnpj(c?.cnpj)}</div>
                        {+c?.vinculavel ? (
                          <div className={`w-[470px] text-left flex whitespace-pre`}><div className={cort}>{nome1}</div><div>{nome2}</div></div>
                        ) : (
                          <div className={`w-[470px] text-left flex`}>{c?.nome}</div>
                        )}
                        <div className="w-44 text-left">{c?.cidade}</div>
                        <div className="w-7 text-left">{c?.uf}</div>
                        <div className="w-56 text-left">{c?.fone} {c?.celular}</div>
                        {/*
                        <div className="w-4 text-center">{c?.isMaster}</div>
                        <div className="w-4 text-center">{c?.vinculado}</div>
                        <div className="w-4 text-center">{c?.vinculavel}</div> */}
                      </div>
                      {acao && <div className="min-w-[90px] pl-[6px] text-left rounded-[4px] border border-solid border-blue-400 bg-blue-200 hover:bg-blue-300 text-sm" onClick={() => handleClickVincular(c)}>{acao}</div>}
                    </div>
                  )
                })}
              </div>
              <div className="form-vinculo flex flex-col h-32 px-4 py-1 bg-slate-100">
                {/* para vincular, clique no cliente master e depois no cliente vinculado */}
                <div className="font-bold text-lg"> Clientes a Vincular</div>
                <div className="flex gap-2 font-bold">
                  <div className="w-20 text-right">Principal:</div>
                  <div className="w-12 text-right">{clientesToVinc[0]?.id}</div>
                  <div className="w-36 text-left">{formatarCpfCnpj(clientesToVinc[0]?.cnpj)}</div>
                  <div className="w-[550px] text-left">{clientesToVinc[0]?.nome}</div>
                  {/* <div className="w-48 text-left">{clientesToVinc[0]?.cidade}</div>
                  <div className="w-8 text-left">{clientesToVinc[0]?.uf}</div> */}
                </div>
                <div className="flex gap-2 font-semibold text-red-400">
                  <div className="w-20 text-right">A vincular:</div>
                  <div className="w-12 text-right">{clientesToVinc[1]?.id}</div>
                  <div className="w-36 text-left">{formatarCpfCnpj(clientesToVinc[1]?.cnpj)}</div>
                  <div className="w-[550px] text-left">{clientesToVinc[1]?.nome}</div>
                  {/* <div className="w-48 text-left">{clientesToVinc[1]?.cidade}</div>
                  <div className="w-8 text-left">{clientesToVinc[1]?.uf}</div> */}
                </div>
                <div className="flex gap-4">
                  <Button className="mt-2 w-20" type="primary" disabled={clientesToVinc.length !== 2} onClick={handleButtonVincular}>Vincular</Button>
                  <Button className="mt-2 w-20" disabled={clientesToVinc.length === 0} onClick={handleButtonCancel}>Cancelar</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalClienteDetalhe title="Detalhes do Cliente" showModalCliente={showModalCliente} hideModalCliente={hideModalCliente} cliente={cliente} resumo={resumo} />

      </div>


    </Layout>
  )
}

