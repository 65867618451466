import { useQuery } from "@tanstack/react-query";
import { apierp } from "../../services/api";
import { categoriasProdutos } from "../../data/dados";

const fetchEmpresas = async () => {
    const response = await apierp.get('empresas')
    return response?.data
}

const fetchSituacoesPedidosVenda = async () => {
    const dados = {
        "metodo": "obterSituacoesPedidoVenda",
        "param": {
            // "empresaId": empresaId
        }
    }
    const response = await apierp.post('pedidosvenda', dados)
    return response?.data
}

const fetchVendedores = async () => {
    const dados = {
        "metodo": "obterVendedores",
        "param": {
            // "empresaId": empresaId
        }
    }
    const response = await apierp.post('pedidosvenda', dados)
    return response?.data
}

const fetchProdutos = async () => {
    // console.log('fetchProdutos', id)
    const response = await apierp.get(`produtosbt`)
    // console.log('fetchProdutos2', response.data)
    return response?.data
}

// const fetchPedidosVenda = async (data: any) => {
//     // console.log('fetchPedidosVenda', data)
//     const dados = {
//         "metodo": "obterPedidosVendasPorFiltros",
//         "param": {
//             "data": data,
//         }
//     }
//     // console.log('fetchPedidosVenda', dados)
//     const response = await apierp.post('pedidosvenda', dados)
//     // console.log('fetchPedidosVenda2')
//     return response?.data
// }

const fetchPedidosVenda = async (param: any) => {
    // console.log('fetchPedidosVenda', param)
    const dados = {
        "metodo": "obterPedidosVendasPorFiltros",
        "param": param
    }
    // console.log('fetchPedidosVenda', dados)
    const response = await apierp.post('pedidosvenda', dados)
    // console.log('fetchPedidosVenda2')
    return response?.data
}

const fetchPedidoVenda = async (pedidoVendaId: any) => {
    // console.log('fetchPedidoVenda', data)
    const dados = {
        "metodo": "obterPedidoVendaPorId",
        "param": {
            "id": pedidoVendaId,
        }
    }
    // console.log('fetchPedidosVenda', dados)
    const response = await apierp.post('pedidosvenda', dados)
    // console.log('fetchPedidosVenda2')
    return response?.data
}

const fetchPixPedidoVenda = async (pedidoVendaId: any) => {
    // console.log('fetchPixPedidoVenda', pedidoVendaId)
    const dados = {
        "metodo": "obterPixPedidoVendaPorPvId",
        "param": {
            "id": pedidoVendaId,
        }
    }
    // console.log('fetchPedidosVenda', dados)
    const response = await apierp.post('pedidosvenda', dados)
    // console.log('fetchPedidosVenda2')
    return response?.data
}

const fetchKeyPixParaLink = async (empresaId: any, pedidoVendaId: any) => {
    // console.log('fetchKeyPixParaLink', pedidoVendaId)
    const dados = {
        "metodo": "gerarKeyPixParaLink",
        "param": {
            "chave": `pixV1#${empresaId}-${pedidoVendaId}`
        }
    }
    // console.log('fetchKeyPixParaLink', dados)
    const response = await apierp.post('pedidosvenda', dados)
    // console.log('fetchKeyPixParaLink', response.data)
    return response?.data
}

const fetchFormasPagamento = async () => {
    const dados = {
        "metodo": "obterFormasPagamento",
        "param": {
        }
    }
    const response = await apierp.post('pedidosvenda', dados)
    return response?.data
}


const fetchBlingVendedoresEmp = async (empresaId?: any) => {
    // console.log('fetchBlingCategoriasReceitaDespesa', id)
    const dados = {
        "metodo": "getVendedores",
        "param": {
            "empresaId": empresaId
        }
    }
    // console.log('fetchBlingVendedores', dados)
    const response = await apierp.post('bling/v3/pedidosvenda', dados)
    // console.log('fetchBlingVendedores2', id)
    return response?.data
}

const fetchBlingCategoriasReceitaDespesaEmp = async (empresaId?: any) => {
    // console.log('fetchBlingCategoriasReceitaDespesa', id)
    const dados = {
        "metodo": "getCategoriasReceitaDespesa",
        "param": {
            "empresaId": empresaId
        }
    }
    // console.log('fetchBlingCategoriasReceitaDespesa', dados)
    const response = await apierp.post('bling/v3/pedidosvenda', dados)
    // console.log('fetchBlingCategoriasReceitaDespesa2', id)
    return response?.data
}

const fetchBlingFormasPagamentoEmp = async (empresaId?: any) => {
    const dados = {
        "metodo": "getFormasPagamento",
        "param": {
            "empresaId": empresaId
        }
    }
    const response = await apierp.post('bling/v3/pedidosvenda', dados)
    return response?.data
}

const fetchBlingProdutosEmp = async (empresaId?: any) => {
    const response = await apierp.get(`blingprodutosbyempresaid/${empresaId}`)
    return response?.data
}

const fetchPixWebhook = async (txId: any) => {
    // console.log('fetchPixWebhook', txId)
    const response = await apierp.get(`pix/webhooks/${txId}`)
    // console.log('fetchPixWebhook', response.data)
    return response?.data
}


export function useDadosPedidosVenda(param?: any, pedidoVendaId?: any, empresaId?: any, txId?: any) {

    const queryEmpresas = useQuery({
        queryKey: ['empresas'],
        queryFn: fetchEmpresas,
        retry: false
        // enabled: !!params,
    });

    const querySituacoesPedidoVenda = useQuery({
        queryKey: ['situcacoespedidovenda'],
        queryFn: fetchSituacoesPedidosVenda,
        retry: false,
        refetchOnWindowFocus: true
    });
    
    const queryVendedores = useQuery({
        queryKey: ['vendedores'],
        queryFn: fetchVendedores,
        retry: false,
        refetchOnWindowFocus: true
    });

    const queryFormasPagamento = useQuery({
        queryKey: ['formaspagamento'],
        queryFn: fetchFormasPagamento,
        retry: false,
        refetchOnWindowFocus: true
    });

    const queryProdutos = useQuery({
        queryKey: ['produtos'],
        queryFn: fetchProdutos,
        retry: false,
        refetchOnWindowFocus: true
    });

    const queryPedidosVenda = useQuery({
        queryKey: ['pedidosvenda', param],
        queryFn: () => fetchPedidosVenda(param),
        retry: false,
        refetchOnWindowFocus: true,
        refetchInterval: 10000,
    });

    const queryPedidoVenda = useQuery({
        queryKey: ['pedidovenda', pedidoVendaId],
        queryFn: () => fetchPedidoVenda(pedidoVendaId),
        retry: false,
        refetchOnWindowFocus: true,
        enabled: !!pedidoVendaId,
    });

    const queryPixPedidoVenda = useQuery({
        queryKey: ['pixpedidovenda', pedidoVendaId],
        queryFn: () => fetchPixPedidoVenda(pedidoVendaId),
        retry: false,
        refetchOnWindowFocus: true,
        enabled: !!pedidoVendaId,
    });

    const queryKeyPixParaLink = useQuery({
        queryKey: ['pedidovenda', empresaId, pedidoVendaId],
        queryFn: () => fetchKeyPixParaLink(empresaId, pedidoVendaId),
        retry: false,
        refetchOnWindowFocus: true,
        enabled: !!empresaId && !!pedidoVendaId,
    })

    const queryBlingVendedoresEmp = useQuery({
        queryKey: ['blingvendedoresemp', empresaId],
        queryFn: () => fetchBlingVendedoresEmp(empresaId),
        retry: false,
        enabled: !!empresaId,
        refetchOnWindowFocus: true
    });

    const queryBlingCategoriasReceitaDespesaEmp = useQuery({
        queryKey: ['blingcategoriasreceitadespesaemp', empresaId],
        queryFn: () => fetchBlingCategoriasReceitaDespesaEmp(empresaId),
        retry: false,
        enabled: !!empresaId,
        refetchOnWindowFocus: true
    });
    
    const queryBlingFormasPagamentoEmp = useQuery({
        queryKey: ['blingformaspagamentoemp', empresaId],
        queryFn: () => fetchBlingFormasPagamentoEmp(empresaId),
        retry: false,
        enabled: !!empresaId,
        refetchOnWindowFocus: true
    });

    const queryBlingProdutosEmp = useQuery({
        queryKey: ['blingprodutosemp', empresaId],
        queryFn: () => fetchBlingProdutosEmp(empresaId),
        retry: false,
        enabled: !!empresaId,
        refetchOnWindowFocus: true
    });

    const queryPixWebhook = useQuery({
        queryKey: ['webhooks', txId],
        queryFn: () => fetchPixWebhook(txId),
        retry: false,
        enabled: !!txId,
        refetchOnWindowFocus: true
    })

    return {
        qrEmpresas: queryEmpresas,
        qrSituacoesPedidoVenda: querySituacoesPedidoVenda,
        qrVendedores: queryVendedores,
        qrFormasPagamento: queryFormasPagamento,
        qrProdutos: queryProdutos,
        qrPedidosVenda: queryPedidosVenda,
        qrPedidoVenda: queryPedidoVenda,
        qrPixPedidoVenda: queryPixPedidoVenda,
        qrKeyPixParaLink: queryKeyPixParaLink,
        qrPixWebhook: queryPixWebhook,

        qrBlingVendedores: queryBlingVendedoresEmp,
        qrBlingCategoriasReceitaDespesa: queryBlingCategoriasReceitaDespesaEmp,
        qrBlingFormasPagamento: queryBlingFormasPagamentoEmp,
        qrBlingProdutos: queryBlingProdutosEmp,
    };

}