import { useEffect, useMemo, useRef, useState } from "react"
import Highlighter from 'react-highlight-words';
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { InputRef, Modal, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAuth } from "../../contexts/auth";
import { apierp } from '../../services/api';
import { customMessage } from "../../components/template/CustomMessage";
import DatePicker from "react-datepicker";


import useWindowSize from "../../hooks/useWindowSize";

import { Button, Col, Drawer, Form, Input, message, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import SpinnerNew from "../../components/template/SpinnerNew";
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../utils/functions";
import Layout from "../../components/template/Layout";
import { useGeral } from "../../contexts/geral";
import Tabela from "../../components/template/Tabela";

import isPermitted from "../../hooks/isPermitted";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { useDadosPedidosVenda } from "./useDadosPedidosVenda";
import { columnsPedidoVenda } from "./columnsPedidoVenda";
import { dateFormat } from "../../utils/dateFunctions";
import DrawerFormPedidoVenda from "./DrawerFormPedidoVenda";
import { useContatos } from "./useContatos";
import { useDebounce } from "use-debounce";
import { FaRegHourglass } from "react-icons/fa";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default function PedidoVenda() {

    const [innerW, innerH] = useWindowSize();
    // const now = new Date()
    // console.log(now)
    // const [mesref, setMesRef] = useState(new Date())

    // const [pageSize, setPageSize] = useState(100)
    // const [param, setParam] = useState(null as any)


    const [pagina, setPagina] = useState(1)
    const [limite, setLimite] = useState(200)
    const [pedidoVendaId, setPedidoVendaId] = useState(null as any)
    const [txId, setTxId] = useState(null as any)
    const [empresaId, setEmpresaId] = useState(null as any)
    const [clienteId, setClienteId] = useState(null as any)
    const [transportadorId, setTransportadorId] = useState(null as any)
    const [vendedorId, setVendedorId] = useState(null as any)
    const [naoEnviados, setNaoEnviados] = useState(true)

    const [drawerFormPedidoVendaVisible, setDrawerFormPedidoVendaVisible] = useState(false)
    const [operacao, setOperacao] = useState(null as string | null)
    const [pedidoVenda, setPedidoVenda] = useState(null as any)
    const [termoCli, setTermoCli] = useState(null as any);
    const [valueDebounceCli] = useDebounce(termoCli, 500);
    const [termoTra, setTermoTra] = useState(null as any);
    const [valueDebounceTra] = useDebounce(termoTra, 500);
    const [clientes, setClientes] = useState([] as any[])
    const [cliente, setCliente] = useState(null as any)
    const [transportadores, setTransportadores] = useState([] as any[])

    // const param = { pagina, limite }
    const param = { pagina, limite, naoEnviados }
    const { qrEmpresas, qrSituacoesPedidoVenda, qrVendedores, qrFormasPagamento, qrProdutos, qrPedidosVenda, qrPedidoVenda, qrPixPedidoVenda, qrKeyPixParaLink, qrBlingVendedores, qrBlingCategoriasReceitaDespesa, qrBlingFormasPagamento, qrBlingProdutos, qrPixWebhook } = useDadosPedidosVenda(param, pedidoVendaId, empresaId, txId)

    const { qrCliente, qrTransportador, qrClientes, qrTransportadores, qrBlingCliente, qrBlingTransportador } = useContatos(clienteId, transportadorId, valueDebounceCli, valueDebounceTra, empresaId)
    // console.log('qrCliente', qrCliente.data)
    // console.log('qrClientes', qrClientes.data)
    useEffect(() => {
        // console.log('useEffect-qrPedidoVenda.data', qrPedidoVenda.data)
        if (qrPedidoVenda.data) {
            // setPedidoVenda(qrPedidoVenda.data)
            setEmpresaId(qrPedidoVenda.data.empresaId)
            setClienteId(qrPedidoVenda.data.clienteId)
            setTransportadorId(qrPedidoVenda.data.transportadorId)
            setVendedorId(qrPedidoVenda.data.vendedorId)
        } else {
            // setPedidoVenda(null)
        }

    }, [qrPedidoVenda.data])

    useEffect(() => {
        if (qrCliente.data) {
            setClientes([qrCliente.data])
            setCliente(qrCliente.data)
        } else {
            // setClientes([])
            setCliente(null)
        }
    }, [qrCliente.data])

    useEffect(() => {
        if (qrTransportador.data) {
            setTransportadores([qrTransportador.data])
            // setTransportadorId(qrTransportador.data.id)
        } else {
            setTransportadores([])
        }
    }, [qrTransportador.data])

    useEffect(() => {
        if (qrClientes.data) {
            setClientes(qrClientes.data)
        } else {
            setClientes([])
        }
    }, [qrClientes.data])

    useEffect(() => {
        if (qrTransportadores.data) {
            setTransportadores(qrTransportadores.data)
        } else {
            // setTransportadores([])
        }
    }, [qrTransportadores.data])

    // console.log('clientes', clientes)
    // console.log('transportadores', transportadores)

    const colunas = columnsPedidoVenda({})

    const handleRow = async (record: any) => {
        // console.log('handleRow', record)
        setPedidoVendaId(record.id)
        // setTimeout(() => {
        setDrawerFormPedidoVendaVisible(true)
        setOperacao('Alteração')
        // }, 200)
    }
    const hideDrawerFormPedidoVenda = () => {
        setPedidoVendaId(0)
        setDrawerFormPedidoVendaVisible(false)
    }
    const newPedido = () => {
        // setPedidoVenda(null)
        setPedidoVendaId(0)
        setEmpresaId(null)
        setClienteId(null)
        setTermoCli(null)
        setClientes([])
        setTransportadorId(null)
        setTermoTra(null)
        setVendedorId(null)
        setDrawerFormPedidoVendaVisible(true)
        setOperacao('Inclusão')
    }

    const filtraSituacao = (checked: any) => {
        setNaoEnviados(checked)
    }

    const wTab = 1458
    const htb = `calc(100vh - 149px - 110px)`
    const hsc = `calc(100vh - 180px - 109px - 33px)` // 1 a menos que htb 33px do DatePicker
    const divTotalLeft = innerW > 640 ? 'left-[144px]' : ''
    // use memo em componente <Tabela> para evitar re-render desnecessario
    const tabela = useMemo(() => {
        // console.log('useMemo tabela')
        return (
            <Tabela className="mx-5" id='pedidosVenda' titulo={'Pedidos-Venda'} wTab={wTab} htb={htb} hsc={hsc} dataSource={qrPedidosVenda.data} columns={colunas} handleRow={handleRow} loading={qrPedidosVenda.isLoading} rowKey={'id'} />
        )
    }, [qrPedidosVenda.data])

    // let corPicker = 'bg-blue-600'
    // if (mesref !== null) {
    //     corPicker = dateFormat(mesref, 'yyyy-MM-dd') === dateFormat(now, 'yyyy-MM-dd') ? 'bg-blue-600' : 'bg-red-600'
    // }

    const botoes = () => {
        const bg = naoEnviados ? 'bg-blue-600' : 'bg-green-600'
        return (
            <Space size={10}>
                {/* <Button onClick={() => printDiv('printableDiv-pedidos', 'pedidos')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
            <Button onClick={() => copyToClipboard('pedidos')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
            <Button onClick={() => downloadToCsv('pedidos', "Menu.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button> */}
                <div className=" ml-1 text-white">{'Situacao:'}</div>
                <Switch className={`min-w-[100px] text-left ${bg}`} checkedChildren="Nao Enviados" unCheckedChildren="Todos" checked={naoEnviados} onChange={filtraSituacao} />
            </Space>
        )
    }

    const trbg = qrPedidosVenda.isFetching ? 'bg-blue-500' : 'bg-transparent'
    const transition = !qrPedidosVenda.isFetching ? 'duration-1000 ease-in-out' : 'transition-none'

    return (
        <Layout titulo={"Pedidos de Venda com PIX"} botoes={botoes()}>
            {/* <h2 className="mx-auto text-red-500">Funcionalidade em teste</h2> */}
            <div className={`flex mx-auto gap-2 items-center`} style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
                <span className={`w-6 h-6 rounded-full ${trbg} ${transition} flex justify-center items-center`}><FaRegHourglass className="animate-spin text-white" /></span>
                <Button className="mx-auto" onClick={() => newPedido()} type="primary" icon={<PlusOutlined />} title="Novo">Novo Pedido</Button>
                <span className={`w-6 h-6 rounded-full bg-transparent`}></span>
            </div>
            {/*
            <div className="mx-auto">
                <DatePicker disabled={qrPedidosVenda.isLoading}
                    // wrapperClassName="btmonth"
                    className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
                    selected={mesref}
                    locale="pt-BR"
                    onChange={(date: any) => setMesRef(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    todayButton="Hoje"
                    popperPlacement="bottom"
                />
            </div>
            */}
            <SpinnerNew loading={qrPedidosVenda.isLoading} />
            <div>
                {tabela}
            </div>

            {qrPedidoVenda.status === "success" || operacao === "Inclusão" ?
                <DrawerFormPedidoVenda hideDrawerFormPedidoVenda={hideDrawerFormPedidoVenda} drawerFormPedidoVendaVisible={drawerFormPedidoVendaVisible}
                    operacao={operacao}
                    formasPagamento={qrFormasPagamento?.data?.filter((fp: any) => !!fp.tipoForma)}
                    // formasPagamento={qrFormasPagamento.data}
                    situacoesPedidoVenda={qrSituacoesPedidoVenda.data}
                    vendedores={qrVendedores.data.sort((a: any, b: any) => a.nome.localeCompare(b.nome))}
                    empresas={qrEmpresas.data}
                    pedidoVenda={qrPedidoVenda.data}
                    pixPedidoVenda={qrPixPedidoVenda.data}
                    keyPixParaLink={qrKeyPixParaLink.data}
                    clientes={clientes}
                    cliente={cliente}
                    termoCli={termoCli}
                    produtos={qrProdutos.data}
                    blingCliente={qrBlingCliente.data}
                    blingTransportador={qrBlingTransportador.data}
                    blingProdutos={qrBlingProdutos.data}
                    // blingFormasPagamento={qrBlingFormasPagamento?.data?.filter((fp: any) => fp.tipoPagamento === 17 && fp.situacao === 1)}
                    blingFormasPagamento={qrBlingFormasPagamento?.data?.filter((fp: any) => fp.situacao === 1)}
                    blingCategoriasReceitaDespesa={qrBlingCategoriasReceitaDespesa.data}
                    blingVendedores={qrBlingVendedores.data}
                    pixWebhook={qrPixWebhook.data}
                    vendedorId={vendedorId} setVendedorId={setVendedorId}

                    // handleSearchCli={handleSearchCli} handleChangeCli={handleChangeCli}
                    transportadores={transportadores} termoTra={termoTra}
                    // handleSearchTra={handleSearchTra} handleChangeTra={handleChangeTra}
                    setTransportadores={setTransportadores} setClientes={setClientes} setItemPedidoVendaId={setPedidoVendaId}
                    setTermoCli={setTermoCli} setTermoTra={setTermoTra} setEmpresaId={setEmpresaId} setClienteId={setClienteId} setTransportadorId={setTransportadorId} setTxId={setTxId}
                />
                : null
            }
            <div className={`divtotal absolute flex items-center h-10 px-4 bottom-8 ${divTotalLeft} text-white`}>{qrPedidosVenda.data?.length} Pedidos</div>
        </Layout>
    )
}