import { useEffect, useRef, useState } from "react"
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { useGeral } from "../../../contexts/geral";
import useConsumoMensalMedio from "./useConsumoMensalMedio";
// import RelEstoqueMedio from "./RelEstoqueMedio";
import Layout from "../../../components/template/Layout";
import SpinnerNew from "../../../components/template/SpinnerNew"
import { dateFormat, dateMySqlToPtBr, dateOnly } from "../../../utils/dateFunctions";
import Data from "../../../logic/utils/Data";
import { Button, Col, Drawer, Form, Input, InputRef, Modal, Row, Space, Typography, message } from "antd";
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FilterOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../../utils/functions";
import Tabela from "../../../components/template/Tabela";
import { columnsConsumoMedioMensal } from "./columnsConsumoMedioMensal";
import { FilterConfirmProps } from "antd/lib/table/interface";
import isPermitted from "../../../hooks/isPermitted";
import { apierp } from "../../../services/api";
import { customMessage } from "../../../components/template/CustomMessage";
const { Title, Text, Paragraph } = Typography;

export default function ConsumoMensalMedio() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)
  const [qtMeses, setQtMeses] = useState(4)

  // const isPermittedManterProdutos = isPermitted('manter_produtos')
  const isPermittedManterEstoque = isPermitted('manter_estoques')
  const [toggleState, setToggleState] = useState(false)

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [escolhaVisible, setEscolhaVisible] = useState(false)
  const [escolha, setEscolha] = useState('') // newMinimo newPrazo
  const [depositoId, setDepositoId] = useState(1)

  const [formEstMin] = Form.useForm();
  const [formPrzRep] = Form.useForm();
  const [drawerFormEstoqueMinimoVisible, setDrawerFormEstoqueMinimoVisible] = useState(false)
  const [drawerFormPrazoReposicaoVisible, setDrawerFormPrazoReposicaoVisible] = useState(false)
  
  const [lastProdutoSelected, setLastProdutoSelected] = useState(-1)

  const data1 = new Date()
  const data2 = new Date()

  //definir dataInicial : hoje -4 meses primeiro dia yyyy-MM-dd
  const dateIn = new Date(data1.setMonth(data1.getMonth() - qtMeses));
  const dataInicial = dateFormat(Data.primeiroDia(dateIn), "yyyy-MM-dd")

  //definir dataFinal : hoje -1 mese ultimo dia yyyy-MM-dd
  const dateFin = new Date(data2.setMonth(data2.getMonth() - 1));
  const dataFinal = dateFormat(Data.ultimoDia(dateFin), "yyyy-MM-dd")

  //definir dataEstoque : hoje yyyy-MM-yy
  const dataEstoque = dateFormat(new Date(), "yyyy-MM-dd") // yyyy-MM-yy

  const { estoquesMedios, processando, reload } = useConsumoMensalMedio(dataInicial, dataFinal, dataEstoque, depositoId)
  const [produto, setProduto] = useState(null)
  // search filter produto
  interface DataType {
    descricao: string;
    nomeComponente: string;
  }
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedKeys, setSelectedKeys] = useState('');
  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof DataType;
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  //end
  const colunas = columnsConsumoMedioMensal({
    setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText
  })

  const showDrawerFormEstoqueMinimo = () => {
    setButtonsDisabled(false)
    setDrawerFormEstoqueMinimoVisible(true)
  };
  const hideDrawerFormEstoqueMinimo = () => {
    setButtonsDisabled(false)
    // setRowSelected(-1)
    setProduto(null)
    setDrawerFormEstoqueMinimoVisible(false)
  };

  const showDrawerFormPrazoReposicao = () => {
    setButtonsDisabled(false)
    setDrawerFormPrazoReposicaoVisible(true)
  };
  const hideDrawerFormPrazoReposicao = () => {
    setButtonsDisabled(false)
    // setRowSelected(-1)
    setProduto(null)
    setDrawerFormPrazoReposicaoVisible(false)
  };

  const fillFormEstMin = (produto: any) => {
    formEstMin.setFieldsValue({
      id: produto.produtoId,
      codigo: produto.codigo,
      descricao: produto.descricao,
      unidade: produto.unidade,
      estoqueMinimo: formatDecimal(produto.estoqueMinimo, 0),
    });
  };
  const fillFormPrzRep = (produto: any) => {
    formPrzRep.setFieldsValue({
      id: produto.produtoId,
      codigo: produto.codigo,
      descricao: produto.descricao,
      unidade: produto.unidade,
      prazoReposicao: formatDecimal(produto.prazoReposicao, 0),
    });
  };
  
  const showModalEscolha = async (record: any, index: any) => {
    // console.log(record)
    setLastProdutoSelected(index)
    setProduto(record)
    fillFormEstMin(record)
    fillFormPrzRep(record)
    setEscolhaVisible(true)
  };
  const hideModalEscolha = async () => {
    setEscolhaVisible(false)
  };
  const handleEscolha = async (escolha: any) => {
    // console.log(escolha)
    // console.log(produto)
    setEscolha(escolha)
    hideModalEscolha()
    if (escolha === 'newMinimo') {
      showDrawerFormEstoqueMinimo()
    } else {
      showDrawerFormPrazoReposicao()
    }
  };
  
  async function handleSubmit(dados: any) {
    setButtonsDisabled(true)
    // console.log('handleSubmit', escolha, dados)
    //UPDATE
    try {
      const result = await apierp.put(`produtos/${dados.id}/${escolha}`, dados);
      // setToggleState(!toggleState)
      message.success('Produto alterado com sucesso!');
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    } finally {
      escolha === 'newMinimo' && hideDrawerFormEstoqueMinimo()
      escolha === 'newPrazo' && hideDrawerFormPrazoReposicao()
      reload()
    }
    // setToggleState(!toggleState)
  }

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-consMensalMedio', 'consMensalMedio')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('consMensalMedio')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('consMensalMedio', "ConsumoMensalMedio.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
      </Space>
    )
  }

  const wTab = 1500 // liquido = x-8
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144


  return (
    <Layout titulo={`Consumos/Vendas Mensal Médios dos últimos ${qtMeses} meses`} botoes={botoes()}>
      <SpinnerNew loading={processando} htb={htb} />
      <Tabela className="mx-5" id='consMensalMedio' titulo={`Consumos/Vendas Mensal Médios dos últimos ${qtMeses} meses`} botoes={botoes()}
        wTab={wTab} htb={htb} hsc={hsc} dataSource={estoquesMedios} columns={colunas} handleRow={showModalEscolha} lastSelected={lastProdutoSelected} loading={processando} rowKey={'codigo'}
      />

      {/* MODAL FILTER */}
      <Modal title="O que deseja alterar:"
        open={escolhaVisible} 
        onOk={hideModalEscolha} 
        onCancel={() => setEscolhaVisible(false)} 
        footer={[

        ]} centered width={360}>
        {/* <h5>Data:</h5> */}
        <div className="flex justify-around">
          <Button onClick={hideModalEscolha}>Cancel</Button>
          <Button onClick={() => {handleEscolha('newMinimo')}} type="primary">Est. Mínimo</Button>
          <Button onClick={() => {handleEscolha('newPrazo')}} type="primary">Prz. Reposição</Button>
        </div>
      </Modal>

      {/* DRAWER FORM ESTOQUE MINIMO */}
      <Drawer
        title={"Alterar Estoque Mínimo"}
        width={360}
        closable={true}
        // maskClosable={false}
        onClose={hideDrawerFormEstoqueMinimo}
        open={drawerFormEstoqueMinimoVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formEstMin} onFinish={handleSubmit} disabled={!isPermittedManterEstoque} >
            <Title level={5}>{formEstMin.getFieldValue('codigo')} - {formEstMin.getFieldValue('descricao')} ({formEstMin.getFieldValue('unidade')})</Title>
            {/* <Title level={5}>{formEstMin.getFieldValue('descricao')} ({formEstMin.getFieldValue('unidade')})</Title> */}
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="id" hidden noStyle></Form.Item>
                <Form.Item
                  name="estoqueMinimo"
                  label={`Estoque Mínimo em (${formEstMin.getFieldValue('unidade')})`}
                // rules={[{ required: false}]}
                >
                  <Input placeholder="Estoque Mínimo" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={hideDrawerFormEstoqueMinimo}>Cancel</Button>
            <Button onClick={formEstMin.submit} disabled={buttonsDisabled || !isPermittedManterEstoque} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>
      
      {/* DRAWER FORM PRAZO REPOSICAO */}
      <Drawer
        title={"Alterar Prazo Reposicao"}
        width={360}
        closable={true}
        // maskClosable={false}
        onClose={hideDrawerFormPrazoReposicao}
        open={drawerFormPrazoReposicaoVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formPrzRep} onFinish={handleSubmit} disabled={!isPermittedManterEstoque} >
            <Title level={5}>{formPrzRep.getFieldValue('codigo')} - {formPrzRep.getFieldValue('descricao')} ({formPrzRep.getFieldValue('unidade')})</Title>
            {/* <Title level={5}>{formPrzRep.getFieldValue('descricao')} ({formPrzRep.getFieldValue('unidade')})</Title> */}
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="id" hidden noStyle></Form.Item>
                <Form.Item
                  name="prazoReposicao"
                  label={`Prazo de reposição em dias `}
                // rules={[{ required: false}]}
                >
                  <Input placeholder="Prazo de reposição" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={hideDrawerFormPrazoReposicao}>Cancel</Button>
            <Button onClick={formPrzRep.submit} disabled={buttonsDisabled || !isPermittedManterEstoque} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>
    </Layout>
  )
}