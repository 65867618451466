import { useEffect, useState } from "react";
import { dateFormatBr } from "../../utils/dateFunctions";
import { formatDecimalZ } from "../../utils/functions";
import { useDadosPedidosVendaParcelas } from "./useDadosPedidosVendaParcelas";
import { useGeral } from "../../contexts/geral";
import { useNavigate } from "react-router-dom";
import { Button, message, Popconfirm } from "antd";
import { useAuth } from "../../contexts/auth";
import { FaRegHourglass } from "react-icons/fa";
import { useMutateConfirmarPagamentoParcela } from "../PedidoVenda/useMutateConfirmarPagamentoParcela";


export default function PedidoVendaParcela() {

    const { mutate: mutateCPP, isSuccess: isSuccessCPP } = useMutateConfirmarPagamentoParcela()

    const [colors, setColors] = useState([])
    const { userTenant } = useGeral();
    const { user } = useAuth();

    const usuario: any = user
    let navigate = useNavigate();
    const rota = "/zapp/" + usuario.shortName.replaceAll(' ', '_')

    useEffect(() => {
        setColors(userTenant.tenant.colors.split(','))
    }, [])
    const situacaoPedidoVendaId = 5

    const { qrPedidoVendaParcela } = useDadosPedidosVendaParcelas(situacaoPedidoVendaId)
    // console.log('qrPedidoVendaParcela', qrPedidoVendaParcela.data)

    const pagamentoConfirmado = async (p: any) => {
        // console.log('pagamentoConfirmado', p)
        // console.log('pagamentoConfirmado', p.pedidoVendaId, p.id, p.valor)

        mutateCPP({ id: p.pedidoVendaId, parcelaId: p.id, valorPago: p.valor })
        message.success('Pagamento confirmado com sucesso!');
    }

    function cancelConfirmaPagamento(e: any) {
        message.error('Pagamento não confirmado');
    }

    const trbg = qrPedidoVendaParcela.isFetching ? 'text-white' : 'text-transparent'
    const transition = !qrPedidoVendaParcela.isFetching ? 'duration-1000 ease-in-out' : 'transition-none'

    return (
        <div className="flex flex-col h-screen w-screen max-w-screen-sm mx-auto" style={{ backgroundColor: colors[3] }}>
            <div className="header flex mx-auto justify-center items-center fixed top-0 w-screen max-w-screen-sm text-white px-2 py-2 z-10" style={{ backgroundColor: colors[1] }}>
                <span className={`w-8 h-8 rounded-full flex justify-center items-center pb-1 z-15`}><FaRegHourglass className={`animate-spin ${trbg} ${transition}`} /></span>
                <div className='h-8 text-center text-lg font-medium'>Pedidos aguardando pagamento</div>
                <span className={`w-8 h-8 rounded-full bg-transparent`}></span>
            </div>
            <div className="content flex flex-col gap-4 overflow-auto px-2 mt-14 mb-12 [&::-webkit-scrollbar]:w-0 [&::-webkit-scrollbar-thumb]:bg-gray-400/60 [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-gray-400" style={{ backgroundColor: colors[3] }} >
                {/* <div className="content flex flex-col gap-4 overflow-auto px-2 mt-14 mb-12" > */}
                {qrPedidoVendaParcela.isLoading && <div>Loading...</div>}
                {qrPedidoVendaParcela.isError && <div>{qrPedidoVendaParcela.error.message}</div>}
                {qrPedidoVendaParcela.data?.map((pvp: any) => {
                    return (
                        <div key={pvp.id} className="card flex flex-col px-1 py-1 border border-black border-solid rounded-md bg-slate-400">
                            <div className="flex justify-between gap-1">
                                <div className="w-8/12 px-0.5 font-semibold bg-white text-[11px] text-left">{pvp.empresa}</div>
                                <div className="w-4/12 px-0.5 font-semibold bg-white text-[11px] text-right">nº zApp: {pvp.id}</div>
                            </div>
                            <div className="flex flex-col mt-1">
                                <div className="w-full px-0.5 font-semibold bg-white text-[11px] text-left">{pvp.cliente}</div>
                            </div>
                            <div className="flex flex-col gap-0.5 mt-2 bg-blackx">
                                <div className="text-left font-semibold text-[10px]">Produtos</div>
                                {pvp.itens.map((i: any) => {
                                    // console.log('pvp', pvp)
                                    return (
                                        <div key={i.id} className="flex justify-left font-semibold text-[10px] gap-0.5">
                                            <div className="w-full px-0.5 bg-white text-left break-all whitespace-normal overflow-wrap-anywhere ">{i.descricao}</div>
                                            <div className="flex gap-0.5">
                                                <div className="w-6 min-w-6 px-0.5 flex justify-end items-center bg-white text-right">{i.quantidade}</div>
                                                <div className="w-12 min-w-12 px-0.5 flex justify-end items-center bg-white text-right">{formatDecimalZ(i.precoUnitario, 2)}</div>
                                                <div className="w-16 min-w-16 px-0.5 flex justify-end items-center bg-white text-right">{formatDecimalZ(i.precoTotal, 2)}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="gap-0.5 mt-2" hidden={!pvp.frete && !pvp.outrasDespesas && !pvp.desconto}>
                                <div className="text-left font-semibold text-[10px]">Outros</div>
                                <div className="flex flex-1 gap-1 h-6 text-left font-semibold text-[10px]">
                                    <div className="flex flex-1" hidden={!pvp.frete}>
                                        <div className="flex  px-0.5 flex-1 justify-end items-center bg-white text-right">Frete:</div>
                                        <div className="w-12 min-w-16 px-0.5 flex justify-end items-center bg-white text-right">{formatDecimalZ(pvp.frete || 0, 2)}</div>
                                    </div>
                                    <div className="flex flex-1" hidden={!pvp.outrasDespesas}>
                                        <div className="flex  px-0.5 flex-1 justify-end items-center bg-white text-right">Outras Desp:</div>
                                        <div className="w-12 min-w-16 px-0.5 flex justify-end items-center bg-white text-right">{formatDecimalZ(pvp.outrasDespesas || 0, 2)}</div>
                                    </div>
                                    <div className="flex flex-1" hidden={!pvp.desconto}>
                                        <div className="flex  px-0.5 flex-1 justify-end items-center bg-white text-right">Desconto:</div>
                                        <div className="w-12 min-w-16 px-0.5 flex justify-end items-center bg-white text-right">{formatDecimalZ(pvp.desconto || 0, 2)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-0.5 mt-2">
                                <div className="text-left font-semibold text-[10px]">Parcelas</div>
                                {pvp.parcelas.map((p: any) => {
                                    // console.log('p', p)
                                    const bgcor = p.tipoForma === 'PSC' || p.tipoForma === 'OAV' ? 'bg-blue-500' : 'bg-red-400'
                                    const datapag = p.dataPagamento
                                        ? <div className="flex flex-1 justify-center items-center h-5 mx-2 ">{dateFormatBr(p.dataPagamento, 'dd/MM/yyyy HH:mm')}</div>
                                        : p.tipoForma === 'OAV' || p.tipoForma === 'PSC'
                                            ? <Popconfirm disabled={false}
                                                title="Deseja realmente confirmar o pagamento?"
                                                onConfirm={() => { pagamentoConfirmado(p) }}
                                                onCancel={cancelConfirmaPagamento}
                                                okText="Sim"
                                                cancelText="Não"
                                            >
                                                {/* <Button onClick={() => { }} type="primary" danger disabled >Delete</Button> */}
                                                <div className={`flex flex-1 justify-center items-center h-5 mx-2 rounded-sm ${bgcor} text-white cursor-pointer`}>Confirmar Pag.</div>
                                            </Popconfirm>
                                            : p.tipoForma === 'PCC' ? <div className="text-yellow-600 mx-auto font-bold">Aguardando Pag.</div> : null
                                    return (
                                        <div key={p.id} className="flex justify-between font-semibold bg-slate-400 text-[10px] gap-0.5">
                                            <div className="flex-1 px-0.5 py-1 bg-white text-left">{p.formaPagamento}{p.observacoes ? ' / ' + p.observacoes : ''}</div>
                                            <div className="flex gap-0.5">
                                                <div className="flex justify-end items-center w-16 min-w-20 px-0.5 bg-white">{formatDecimalZ(p.valor, 2)}</div>
                                                <div className="flex items-center w-28 min-w-28 px-0  bg-white text-center">{datapag}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}

            </div>
            <div className="footer fixed bottom-0 w-screen max-w-screen-sm px-2 py-2 z-10" style={{ backgroundColor: colors[4] }}>
                {/* <Button size="small" onClick={() => navigate(`${rota}/`)} >Voltar</Button> */}
                <Button size="small" onClick={() => navigate(-1)} >Voltar</Button>
            </div>
        </div>
    );
}