import { Button, Modal, Radio } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { groupBy } from "../../../utils/functions";
import ConsumoPorSemana from "./consumoPorSemana";
import ConsumoPorDia from "./consumoPorDia";
import ConsumoPorQuinzena from "./consumoPorQuinzena";
// import ConsumoPorMes from "./consumoPorMes";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ConsumoPorMes from "./consumoPorMes";

export default function ModalConsumoOP(props: any) {
    const { empresas, mesSelected, consumoOpMes, movimentoMes } = props
    // adicionar a quinzena (1 ou 2) no consumoOpMes
    consumoOpMes.forEach((x: any) => {
        x.quinzena = x.dia <= 15 ? 1 : 2
    })
    // console.log('consumoOpMes', consumoOpMes)
    const mesesUnicos = extrairMesesUnicos(consumoOpMes)
    const quinzenasUnicas = extrairQuinzenasUnicas(consumoOpMes)
    const semanasUnicas = extrairSemanasUnicas(consumoOpMes)
    const diasUnicos = extrairDiasUnicos(consumoOpMes)
    // console.log('semanasUnicas', semanasUnicas)

    let meses: any = []
    mesesUnicos.forEach((mes: number) => {
        const itens = consumoOpMes.filter((x: any) => x.mes === mes)
        // console.log('mes', mes, itens[0].data, itens[itens.length-1].data)
        meses.push({ mes, dataInicial: itens[0].data, dataFinal: itens[itens.length - 1].data })
    })
    let quinzenas: any = []
    quinzenasUnicas.forEach((quinzena: number) => {
        const itens = consumoOpMes.filter((x: any) => x.quinzena === quinzena)
        // console.log('quinzena', quinzena, itens[0].data, itens[itens.length-1].data)
        quinzenas.push({ quinzena, dataInicial: itens[0].data, dataFinal: itens[itens.length - 1].data })
    })
    let semanas: any = []
    semanasUnicas.forEach((wkoy: number) => {
        const itens = consumoOpMes.filter((x: any) => x.wkoy === wkoy)
        // console.log('wkoy', wkoy, itens[0].data, itens[itens.length-1].data)
        semanas.push({ wkoy, dataInicial: itens[0].data, dataFinal: itens[itens.length - 1].data })
    })
    let dias: any = []
    diasUnicos.forEach((dia: number) => {
        const itens = consumoOpMes.filter((x: any) => x.dia === dia)
        dias.push({ dia, data: itens[0].data })
    })
    // console.log('dias', dias)
    // console.log('semanas', semanas)

    const consumoPorMes = groupBy(consumoOpMes, ['empresaId', 'ano', 'mes', 'codigo', 'un'], ['qtd', 'consumo'])
    const consumoPorDia = groupBy(consumoOpMes, ['empresaId', 'ano', 'mes', 'dia', 'codigo', 'un'], ['qtd', 'consumo'])
    const consumoPorSemana = groupBy(consumoOpMes, ['empresaId', 'ano', 'mes', 'wkoy', 'codigo', 'un'], ['qtd', 'consumo'])
    const consumoPorQuinzena = groupBy(consumoOpMes, ['empresaId', 'ano', 'mes', 'quinzena', 'codigo', 'un'], ['qtd', 'consumo'])
    // console.log('consumoPorQuinzena', consumoPorQuinzena)
    // console.log('consumoPorMes', consumoPorMes)
    // console.log('mes', mes)
    // console.log('dias', consumoPorDia)
    // console.log('semanas', consumoPorSemana)

    const documentTitle = `Consumo OP ${empresas[mesSelected?.empresaId - 1]?.nome} ${mesSelected?.mes}/${mesSelected?.ano}`
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef, documentTitle });
    type PeriodType = 'diario' | 'semanal' | 'quinzenal' | 'mensal';
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodType>('diario');

    const periodOptions = [
        { label: 'Diário', value: 'diario' },
        { label: 'Semanal', value: 'semanal' },
        { label: 'Quinzenal', value: 'quinzenal' },
        { label: 'Mensal', value: 'mensal' }
    ];

    const onPeriodChange = (e: any) => {
        setSelectedPeriod(e.target.value);
    };
    return (
        <Modal className="max-h-[1120px]" title="Ordem de Produção Impermeabilizante Não Inflamável - Consumo Cloreto" open={props.showModalConsumoOP} onOk={props.hideModalConsumoOP} onCancel={props.hideModalConsumoOP} footer={[]} centered width={1040}>
            <div className="flex w-full justify-center">
                <Radio.Group
                    options={periodOptions}
                    value={selectedPeriod}
                    onChange={onPeriodChange}
                    className="flex gap-4"
                />
            </div>
            <div ref={contentRef} className="py-4 px-9">
                {selectedPeriod === 'diario' && (
                    <ConsumoPorDia empresas={empresas} mesSelected={mesSelected} consumoPorDia={consumoPorDia} dias={dias} movimentoMes={movimentoMes} />
                )}
                {selectedPeriod === 'semanal' && (
                    <ConsumoPorSemana empresas={empresas} mesSelected={mesSelected} consumoPorSemana={consumoPorSemana} semanas={semanas} movimentoMes={movimentoMes} />
                )}
                {selectedPeriod === 'quinzenal' && (
                    <ConsumoPorQuinzena empresas={empresas} mesSelected={mesSelected} consumoPorQuinzena={consumoPorQuinzena} quinzenas={quinzenas} movimentoMes={movimentoMes} />
                )}
                {selectedPeriod === 'mensal' && (
                    <ConsumoPorMes empresas={empresas} mesSelected={mesSelected} consumoPorMes={consumoPorMes} meses={meses} movimentoMes={movimentoMes} />
                )}
            </div>
            <div className="flex w-full justify-center mt-2">
                {/* <Button onClick={() => printDiv2('printableModal')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button> */}
                <Button onClick={() => reactToPrintFn()} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
            </div>
        </Modal>
    )
}

function extrairDiasUnicos(array: any[]): number[] {
    const dias = array.map(item => item.dia);
    // console.log('dias', dias)       
    const diasUnicos = Array.from(new Set(dias));
    return diasUnicos.sort((a, b) => a - b);
}

function extrairSemanasUnicas(array: any[]): number[] {
    const semanas = array.map(item => item.wkoy);
    // console.log('semanas', semanas)
    const semanasUnicas = Array.from(new Set(semanas));
    return semanasUnicas.sort((a, b) => a - b);
}

function extrairQuinzenasUnicas(array: any[]): number[] {
    const quinzenas = array.map(item => item.quinzena);
    // console.log('quinzenas', quinzenas)
    const quinzenasUnicas = Array.from(new Set(quinzenas));
    return quinzenasUnicas.sort((a, b) => a - b);
}

function extrairMesesUnicos(array: any[]): number[] {
    const meses = array.map(item => item.mes);
    // console.log('meses', meses)
    const mesesUnicos = Array.from(new Set(meses));
    return mesesUnicos.sort((a, b) => a - b);
}
