import { useQuery } from "@tanstack/react-query";
import { apierp } from "../../services/api";

const fetchPedidosVendaParcela = async (situacaoPedidoVendaId?: any) => {
    const dados = {
        "metodo": "obterPedidosVendaParcelasPorFiltros",
        "param": {
            situacaoPedidoVendaId: situacaoPedidoVendaId
        }
    }
    const response = await apierp.post('pedidosvenda', dados)
    return response?.data
}

export function useDadosPedidosVendaParcelas(situacaoPedidoVendaId?: any) {
    const queryPedidosVendaParcelas = useQuery({
        queryKey: ['pedidosvendaparcela', situacaoPedidoVendaId],
        queryFn: () => fetchPedidosVendaParcela(situacaoPedidoVendaId),
        retry: false,
        refetchOnWindowFocus: true,
        refetchInterval: 10000,
        enabled: !!situacaoPedidoVendaId,
    });


    return { qrPedidoVendaParcela: queryPedidosVendaParcelas };
}