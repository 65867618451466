import { useCallback, useEffect, useState } from 'react'
import useProcessando from '../../../data/hooks/useProcessando'
import { apierp } from '../../../services/api'
import { dateFormat } from '../../../utils/dateFunctions'

export default function useClientes(params: any) {
  // console.log('entrou em useClientes', params)
  const { processando, iniciarProcessamento, finalizarProcessamento } = useProcessando()

  const [clientesBling, setClientesBling] = useState([] as any[])
  const [clientesBlingZapp, setClientesBlingZapp] = useState([] as any[])
  const [clienteZapp, setClienteZapp] = useState([])

  const obterClientes = useCallback(async function () {
    if (!params) return
    // console.log('obterClientes', params)
    try {
      iniciarProcessamento()
      //       // const inicio = performance.now();
      const cliBling: any = []
      const cliBlingZapp: any = []
      const cliZapp: any = []

      // FOR EMPRESAS
      for (const empresa of params.empresas) {

        // obter cliBling
        const dataBling: any = {
          "metodo": "obterClientesPorDocumento",
          "param": {
            "empresaId": +empresa.id,
            "documento": params.documento,
            "resumido": false,
            "criterio": 1
          }
        }
        const response1 = await apierp.post(`bling/v3/clientes`, dataBling) // na verdade é get
        const data1 = await response1.data as any;
        // const response2 = await apierp.get(`blingclientes/${params.documento}/${empresa.id}`)
        // const data2 = await response2.data as any;
        const dataAlteracao = '' // data2.length>0 ? data2[0].contato.dataAlteracao : ''

        cliBling.push({
          empresaId: empresa.id,
          empresaNome: empresa.nome,
          bairro: data1.data[0]?.endereco.geral.bairro,
          celular: data1.data[0]?.celular,
          cep: data1.data[0]?.endereco.geral.cep,
          cidade: data1.data[0]?.endereco.geral.municipio, // *
          municipio: data1.data[0]?.endereco.geral.municipio, // * 
          cnpj: data1.data[0]?.numeroDocumento, // **
          numeroDocumento: data1.data[0]?.numeroDocumento, // **
          complemento: data1.data[0]?.endereco.geral.complemento,
          email: data1.data[0]?.email,
          endereco: data1.data[0]?.endereco.geral.endereco,
          fone: data1.data[0]?.telefone,
          telefone: data1.data[0]?.telefone,
          id: data1.data[0]?.id,
          ie: data1.data[0]?.ie,
          indicadorIe: data1.data[0]?.indicadorIe,
          nome: data1.data[0]?.nome,
          codigo: data1.data[0]?.codigo, // ****
          nomeFantasia: data1.data[0]?.fantasia,
          fantasia: data1.data[0]?.fantasia,
          numero: data1.data[0]?.endereco.geral.numero,
          obsInterna: null,
          rg: data1.data[0]?.rg,
          orgaoEmissor: data1.data[0]?.orgaoEmissor, // ***
          situacao: data1.data[0]?.situacao,
          tipoPessoa: data1.data[0]?.tipo,
          uf: data1.data[0]?.endereco.geral.uf,
          vendedorId: data1.data[0]?.vendedor.id,
          dataAlteracao
        })

      } // END FOR EMPRESAS

      setClientesBling(cliBling)
      obterClientesBlingZapp()
      obterClienteZapp()

      //       // const duracao = Math.round((performance.now() - inicio) / 100) / 10;
      //       // console.log(`obterRankingClientes: ${duracao} segundos`)

    } catch (err) {
      console.log(err)
    } finally {
      finalizarProcessamento()
    }
  }, [params])

  async function obterClientesBlingZapp() {
    if (!params) return
    try {
      // iniciarProcessamento()
      // const inicio = performance.now();
      const cliBlingZapp: any = []

      // FOR EMPRESAS
      for (const empresa of params.empresas) {
        const dataBlingZapp: any = {
          "empresaId": +empresa.id,
          "documento": params.documento
        }

        const response2 = await apierp.post(`clientes/obterblingclizapporempdoc`, dataBlingZapp) // na verdade é get
        const data = await response2.data as any;
        cliBlingZapp.push(data)
      } // END FOR EMPRESAS

      setClientesBlingZapp(cliBlingZapp)
      // const duracao = Math.round((performance.now() - inicio) / 100) / 10;
      // console.log(`obterRankingClientes: ${duracao} segundos`)

    } catch (err) {
      console.log(err)
    } finally {
      // finalizarProcessamento()
    }
  }

  async function obterClienteZapp() {
    if (!params) return
    try {
      // iniciarProcessamento()
      // const inicio = performance.now();
      const cliZapp: any = []

      const dataZapp: any = {
        "documento": params.documento
      }
      const response3 = await apierp.post(`clientes/obterclientepordoc`, dataZapp) // na verdade é get
      const data3 = await response3.data as any;
      // cliZapp.push(data3)
      cliZapp.push(data3===''?null:data3)
      setClienteZapp(cliZapp)
      // const duracao = Math.round((performance.now() - inicio) / 100) / 10;
      // console.log(`obterRankingClientes: ${duracao} segundos`)

    } catch (err) {
      console.log(err)
    } finally {
      // finalizarProcessamento()
    }
  }

  async function reloadClientesBlingZapp() {
    iniciarProcessamento()
    await obterClientesBlingZapp()
    await obterClienteZapp()
    finalizarProcessamento()
  }

  function voltar() {
    // setClientesRanking([])
  }
  function reload() {
    obterClientes()
  }

  useEffect(() => {
    setClientesBling([])
    setClientesBlingZapp([])
    setClienteZapp([])
    obterClientes()
  }, [obterClientes])

  return {
    clientesBling,
    clientesBlingZapp,
    clienteZapp,
    processando,
    iniciarProcessamento,
    finalizarProcessamento,
    voltar,
    reload,
    reloadClientesBlingZapp
  }

}