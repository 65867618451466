
import * as React from "react"
import { useEffect, useState } from "react"

import { Button, Divider, InputRef, Modal, Space, Typography } from "antd";
import DatePicker from "react-datepicker";
import { DownloadOutlined, CopyOutlined, PrinterOutlined,  FilterOutlined } from '@ant-design/icons';

import Layout from "../../../components/template/Layout"
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { useGeral } from "../../../contexts/geral";
import { customMessage } from "../../../components/template/CustomMessage";
import { apierp } from "../../../services/api";
import SpinnerNew from "../../../components/template/SpinnerNew";
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../../utils/functions";
import { dateFormat } from "../../../utils/dateFunctions";
import Tabela from "../../../components/template/Tabela";
import { columnsPrecoMedioGeral } from "./columnsPrecoMedioGeral";
import { FilterConfirmProps } from "antd/lib/table/interface";

const { Title, Text, Paragraph } = Typography;

export default function PrecoMedioGeral() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [modalFilterVisible, setModalFilterVisible] = useState(false)
  const [filterDate, setFilterDate] = useState(new Date("2024-01-01 12:00:00"));

  const [toggleState, setToggleState] = useState(false)
  const [loadingPrecoMedioGeral, setLoadingPrecoMedioGeral] = useState(false)
  const [precoMedioGeral, setPrecoMedioGeral] = useState([])
  const [rowPrecoMedioSelected, setRowPrecoMedioSelected] = useState(-1)
  const [lastPrecoMedioSelected, setLastPrecoMedioSelected] = useState(-1)

  useEffect(() => {
    setLoadingPrecoMedioGeral(true)
    setTimeout(async function () {
      try {
        await loadPrecoMedioGeral()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoadingPrecoMedioGeral(false)
    }, 500);
  }, [toggleState])

  async function loadPrecoMedioGeral() {
    const dados = {
      fromDate: dateFormat(filterDate, "yyyy-MM-dd") // '2022-12-31'
    }
    const response = await apierp.post('relatorios/precomediogeral', dados)
    const data = response.data as any;
    // console.log(data)
    setPrecoMedioGeral(data);
  }

  const showModalFilter = async () => {
    setModalFilterVisible(true)
  };
  const hideModalFilter = async () => {
    setModalFilterVisible(false)
  };
  const handleChangeDate = (newDate: any) => {
    setFilterDate(newDate)
    setToggleState(!toggleState)
    hideModalFilter()
  }
  async function handleRowPrecoMedio(record: any, index: any) {
    setLastPrecoMedioSelected(index)
    setRowPrecoMedioSelected(index)

  }
  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-precomedio', 'precomedio')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('precomedio')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('precomedio', "PrecoMedioGeral.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={() => showModalFilter()} shape="round" size="small" icon={<FilterOutlined />} title="Filtrar data"></Button>
        {/* <div>{innerW}</div> */}
      </Space>
    )
  }

  // search filter produto
  interface DataType {
    descricao: string;
  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedKeys, setSelectedKeys] = useState('');
  const searchInput = React.useRef<InputRef>(null);
  type DataIndex = keyof DataType;
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  //end

  precoMedioGeral.forEach((it:any, index: number) => {
    it.addClass = it.tipo === 0 ? "bg-blue-300x text-red-700 font-bold" : ""
  })

  const colunas = columnsPrecoMedioGeral({
    setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText
  })
  
  const wTab = 1325 + 100
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb

  return (
    <Layout titulo={menu.label + ` desde ${dateFormat(filterDate, "dd/MM/yyyy")} - ${precoMedioGeral.length} linhas`} botoes={botoes()}>
      <SpinnerNew loading={loadingPrecoMedioGeral} />
      <Tabela className="mx-5" id='precomedio' titulo={menu.label + ` desde ${dateFormat(filterDate, "dd/MM/yyyy")}`} wTab={wTab} htb={htb} hsc={hsc} dataSource={precoMedioGeral} columns={colunas} handleRow={handleRowPrecoMedio} rowSelected={rowPrecoMedioSelected} lastSelected={lastPrecoMedioSelected} loading={loadingPrecoMedioGeral} />

      {/* MODAL FILTER */}
      <Modal title="Data inicial:" open={modalFilterVisible} onOk={hideModalFilter} onCancel={() => setModalFilterVisible(false)} footer={[]} centered width={250}>
        {/* <h5>Data:</h5> */}
        <div className="flex justify-around">
          {/* <DatePicker className="w-[170px]" placeholder="Selecione uma data"  onChange={onChangeDate} format={'DD/MM/YYYY'}/> */}
          <DatePicker className="ant-input" selected={filterDate} locale="pt-BR" dateFormat="dd/MM/yyyy" onChange={(date: any) => handleChangeDate(date)} todayButton="Hoje" />
        </div>
      </Modal>

    </Layout>
  )
}

