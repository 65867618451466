import React from 'react';
import QRCode from 'qrcode';

interface QRCodePixProps {
  pixCopiaCola: string;
}

const QRCodePix: React.FC<QRCodePixProps> = ({ pixCopiaCola }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    if (canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, pixCopiaCola, {
        width: 200,
        // height: 200,
        scale: 1,
      }, (error) => {
        if (error) console.error(error);
        // else console.log('QR Code gerado com sucesso!');
      });
    }
  }, [pixCopiaCola]);

  return <canvas ref={canvasRef}></canvas>;
};

// Exemplo de uso no seu componente
// const App: React.FC = () => {
//   const pixCopiaCola = '00020101021226800014br.gov.bcb.pix0114+55119999999999200400br520400005303986540924.005802BR5913Fulano de Tal6008Brasilia62070503***6304B14B'; // Sua chave PIX

//   return (
//     <div>
//       <h1>QR Code PIX</h1>
//       <QRCodePix pixCopiaCola={pixCopiaCola} />
//     </div>
//   );
// };

export default QRCodePix;
