import { useMutation, useQueryClient } from "@tanstack/react-query"
import { apierp } from "../../services/api"
import { message } from "antd"


const submit = async (dados: any ) => {

    const dadosx = {
        "metodo": "gerarPixCopiaECola",
        "param": {
            id: dados.id,
            situacaoPedidoVendaId: dados.situacaoPedidoVendaId
        }
    }
    const response = await apierp.post('pedidosvenda', dadosx)
    if (!(response?.data)) {
        console.log('gerarPixCopiaECola-response\n', response)
    }
    return response?.data
}

export function useMutateGerarPixCopiaECola() {
    const queryClient = useQueryClient()
    const mutate = useMutation({
        mutationFn: submit,
        onSuccess: () => {
            message.success('Pix Copia e Cola gerado com sucesso')
            queryClient.invalidateQueries({ queryKey: ['pedidosvenda']})
            queryClient.invalidateQueries({ queryKey: ['pedidovenda']})
            queryClient.invalidateQueries({ queryKey: ['pixpedidovenda']})
        },
        onError: () => {
            console.log('useMutateGerarPixCopiaECola-onError')
            message.error('Erro ao Gerar Pix Copia e Cola')
        }
    })

    return mutate
}
